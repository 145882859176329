.graphic__undefined {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    @media (max-width: 1200px) {
        & {
            height: 200px;
        }
    }
}
