.info-box {
    margin: 0;
}

.info__box--number {
    margin-top: 0.4rem !important;
}

@media (max-width: 1000px) {
    .info-box {
        margin-bottom: 5px;
    }
}
